/*
 * @Description: 店铺层级树型结构处理工具函数
 * 除了用于“店铺层级树型选择控件 BasisShopLevelTreeSelect”，还可以用于其他仅需要对店铺层级数据进行转换，不适用选择控件的场景
 * @example import { transformRawData } from '@ys/micro-components/BasisShopLevelTreeSelect/useShopLevel'
 */

import _ from 'lodash'
import pinyin from 'pinyin'
import { removeNode, forEach } from '@ys/utils/lib/tree'

export const treeConfig = { id: '_value', children: '_children' }

/**
 * @description: 获取层级或店铺实际值
 * @param {string} value 带有“biz_”或“shop_”开头
 * @return {string} 真实的bizId、shopNo
 */
export const getRealValue = (value = '') => {
  if (value.toString().indexOf('_') > -1) {
    return value.split('_')[1] || undefined
  }
  return value || undefined
}

/**
 * @description: 判断某个值是否为层级
 * @param {string} value 带有“biz_”或“shop_”开头
 * @return {boolean} true 或 false
 */
export const isBiz = (value = '') => {
  return value.toString().startsWith('biz_')
}

/**
 * @description: 获取某层级下的所有有效层级和有效店铺（有效的定义：没被过滤）
 * @param {object} selectedNode 当前选中的层级 {_children,_hiddenShops,_parentId,_searchWords,_title,_value,...}
 * @param {boolean} returnRawShopName 返回的shops中的shopName是否为原始值 false => xxx百货店（淘宝）  true => xxx百货店
 * @param {string | undefined} searchKeyword 搜索的关键词，如果有值则返回符合条件的结果，默认为undefined
 * @return {object} { bizIds, bizList:[{bizId:'',bizName:''], shopNos, shops:[{shopNo:'',shopName:''}] }
 */
export const getAllBizShop = (
  selectedNode,
  returnRawShopName = false,
  searchKeyword = undefined,
) => {
  const bizIds = [] // 所有层级id
  const bizList = [] // 所有层级列表 [{bizId:'',bizName:''}]
  const shopNos = [] // 所有店铺shopNo
  const shops = [] // 所有店铺列表 [{shopNo:'',shopName:''}]

  const traverse = (items) => {
    for (const item of items) {
      const { _children = [], _hiddenShops = [], _value, _title, _rawTitle, _searchWords } = item
      const realValue = getRealValue(_value)

      const targetTitle = returnRawShopName ? _rawTitle : _title
      const targetSearchWords = _searchWords || targetTitle
      const valid =
        !searchKeyword || (searchKeyword && targetSearchWords.indexOf(searchKeyword.trim()) > -1)

      if (item.disabled !== true && valid) {
        if (isBiz(_value)) {
          bizIds.push(realValue)
          bizList.push({ bizId: realValue, bizName: targetTitle })
        } else {
          shopNos.push(realValue)
          shops.push({ shopNo: realValue, shopName: targetTitle })
        }
      }

      if (_children.length) traverse(_children)
      if (_hiddenShops.length) traverse(_hiddenShops)
    }
  }
  if (selectedNode) traverse([selectedNode])

  return { bizIds, bizList, shopNos, shops }
}

/**
 * @description: 获取某层级的有效直属下级层级及该下级层级下所有有效店铺，适用于按层级分组显示或统计数据
 * @param {object} selectedNode 当前选中的层级 {_children,_hiddenShops,_parentId,_searchWords,_title,_value,...}
 * @param {boolean} returnRawShopName 返回的shops中的shopName是否为原始值 false => xxx百货店（淘宝）  true => xxx百货店
 * @return {object} {directlyBiz: [{ bizId, bizName, shops: [{ shopNo, shopName }]}], directlyShopNos, directlyShops, directlyBizShopMap: { xxxBiz: [xxxshopNo] }}
 */
// eslint-disable-next-line max-statements
export const getDirectlyBizShop = (selectedNode, returnRawShopName = false) => {
  const { _children = [], _value, _rawTitle, _title } = selectedNode || {}

  let directlyBiz = [] // 直属下级层级数组 [{ bizId, bizName, shops: [{ shopNo, shopName }]}]
  let directlyShopNos = [] // 直属下级层级包含的所有有效店铺shopNo数组 ['23','22']
  let directlyShops = [] // 直属下级层级包含的所有有效店铺列表 [{ shopNo, shopName }]
  let directlyBizShopMap = {} // 直属下级层级与该层级包含的所有有效店铺shopNo数组的映射关系 { xxxBiz: [xxxshopNo] }

  // 选中了单个店铺或清空全部数据
  if (!isBiz(_value)) {
    if (_value) {
      const shopNo = getRealValue(_value)
      directlyShopNos = [shopNo]
      directlyShops = [{ shopNo, shopName: returnRawShopName ? _rawTitle : _title }]
    }
    return { directlyBiz, directlyShopNos, directlyShops, directlyBizShopMap }
  }

  const { shopNos, shops } = getAllBizShop(selectedNode, returnRawShopName)
  directlyShopNos = shopNos
  directlyShops = shops

  // 表示选中了最后一个层级
  if (_children.length === 0) {
    const bizId = getRealValue(_value)
    directlyBiz = [{ bizId, bizName: _title, shops }]
    directlyBizShopMap = { [bizId]: shopNos }
  } else {
    directlyShopNos = []
    directlyShops = []

    for (let i = 0; i < _children.length; i++) {
      const item = _children[i]

      if (item.disabled === true || !isBiz(item._value)) continue // 跳出本次循环，继续下一个循环

      const { shopNos: itemShopNos, shops: itemShops } = getAllBizShop(item, returnRawShopName)
      const bizId = getRealValue(item._value)

      directlyBiz.push({ bizId, bizName: item._title, shops: itemShops })
      directlyBizShopMap[bizId] = itemShopNos
      directlyShopNos = [...directlyShopNos, ...itemShopNos]
      directlyShops = [...directlyShops, ...itemShops]
    }
  }

  return { directlyBiz, directlyShopNos, directlyShops, directlyBizShopMap }
}

/**
 * @description: 删除没用的属性，减少数据量
 * @param {object} item 原始对象
 * @return {object} 删除没用属性后的对象
 */
const filterProps = (item, options = {}) => {
  let filterValidProps = options.filterValidProps || []
  // 全量返回
  if (filterValidProps === 'all') return item
  if (!Array.isArray(filterValidProps)) filterValidProps = []

  Object.keys(item).map((key) => {
    const validProps = [
      '_value',
      '_rawValue',
      '_title',
      '_rawTitle',
      '_children',
      '_hiddenShops',
      '_parentId',
      'disabled',
      '_searchWords',
      '_hasShops',
      '_isBiz',
      '_isTop',
      options.treeNodeFilterProp,
      ...filterValidProps,
    ]
    if (!validProps.includes(key)) {
      delete item[key]
    }
  })
  return item
}

/**
 * @description: 转换成拼音，用于支持通过拼音搜索
 * @param {string} words 需要转换为拼音的字符串
 * @return {string}
 */
export const transformToPinyin = (words) => {
  // 普通风格，即不带声调
  const res = pinyin(words, { style: pinyin.STYLE_NORMAL }).toString().replace(/,/g, '')
  // 首字母风格，只返回拼音的首字母部分
  const res2 = pinyin(words, { style: pinyin.STYLE_FIRST_LETTER }).toString().replace(/,/g, '')
  return `${res}${res2}`
}

/**
 * @description: 转换层级
 * @param {object} item 层级节点 {id,parentId,bizId,bizName,shops:[]}
 * @param {object} options {disabledBizIds:[],disabledAllBizIds:false,filterOutBizIds:[],filterByUserId:undefined}
 * @return {array} 返回当前层级字段转换及过滤后的结果
 */
const transformBiz = (item, options = {}) => {
  const {
    disabledBizIds = [],
    disabledAllBizIds = false,
    disabledAllNonLeafNodeBizIds = false,
    filterOutBizIds = [],
    filterByUserId,
  } = options

  item._value = `biz_${item.bizId}`
  item._rawValue = item.bizId // 原始数值
  item._title = item.bizName
  item._rawTitle = item._title
  item._searchWords = item._title + transformToPinyin(item._title)
  item._parentId = item.parentId
  item._isBiz = true

  let noAuth = false
  if (filterByUserId && Array.isArray(item.users)) {
    const userIds = item.users.map((user) => user.userId)
    noAuth = filterByUserId && !userIds.includes(filterByUserId)
  }

  // 禁用指定层级 或 所有层级 或 用户没有权限的层级
  item.disabled = disabledBizIds.includes(item.bizId) || disabledAllBizIds || noAuth

  // 如果拥有直属子层级且没有直属店铺，且disabledAllNonLeafNodeBizIds为true，则禁用该层级
  if (_.size(item.children) && !_.size(item.shops || []) && disabledAllNonLeafNodeBizIds)
    item.disabled = true

  // 过滤掉指定层级
  let tempChildren = []
  if (_.size(item.children)) {
    tempChildren = item.children.filter((child) => !filterOutBizIds.includes(child.bizId))
  }

  return tempChildren
}

/**
 * @description: 转换店铺
 * @param {array} shops 店铺数组 [{shopNo: '1231', shopName: '店铺名称', platformId: 127, platformName: '平台名称'}]
 * @param {string} bizId 当前店铺数组所在的层级bizId
 * @param {object} options {disabledShopNos:[], filterOutShopNos:[], treeNodeFilterProp:'', filterValidProps:[],filterByUserId:undefined}
 * @return {array} 经过转换后的店铺数组 [{_value: '店铺shopNo，带“shop_”前缀，如“shop_123”', _title: '店铺名称（平台名称）', _rawTitle: '店铺原始名称，不带平台名称', _searchWords: '用于搜索的字符串',_parentId: '父级层级id，不带“biz_”前缀'}]
 */
const transformShops = (shops, bizId, options = {}) => {
  const { disabledShopNos = [], filterOutShopNos = [], filterByUserId } = options

  const tempShops = []
  _.each(shops, (shop) => {
    shop._value = `shop_${shop.shopNo}`
    shop._rawValue = shop.shopNo // 原始数值
    shop._title = shop.platformName ? `${shop.shopName}（${shop.platformName}）` : shop.shopName
    shop._rawTitle = shop.shopName // 原始名称
    shop._searchWords = shop._title + transformToPinyin(shop._title)
    shop._parentId = bizId
    shop._isBiz = false

    // 禁用指定店铺
    if (disabledShopNos.includes(shop.shopNo)) {
      shop.disabled = true
    }

    let noAuth = false
    if (filterByUserId && Array.isArray(shop.users)) {
      const userIds = shop.users.map((user) => user.userId)
      noAuth = filterByUserId && !userIds.includes(filterByUserId)
    }

    // 过滤掉指定店铺 及 没有权限的店铺
    if (!filterOutShopNos.includes(shop.shopNo) && !noAuth) {
      tempShops.push(shop)
    }

    filterProps(shop, options)
  })
  return tempShops
}

/**
 * @description: 原始数据转换 - 把接口返回的店铺层级原始数据转换成最终使用的格式
 * @param {array} rawData 原始数据（店铺数据放在shops中、层级数据放在children中） [{bizId,bizName,children:[{bizId,bizName,children:[],shops:[],id,parentId},...],id,parentId,shops:[{shopNo: '1231', shopName: '店铺名称', platformId: 127, platformName: '平台名称'}]}]
 * @param {object} options
 * @param {array} options.filterOutShopNos // 需要过滤掉的店铺shopNo数组。不带“shop_”前缀，如：['189','9898','6666']
 * @param {boolean} options.filterOutAllShopNos // 是否过滤掉所有店铺仅保留层级，和filterOutAllBizIds不能同时为true，否则直接没有数据
 * @param {array} options.filterOutBizIds // 需要过滤掉的层级id数组，该层级及其所有下级(包括层级和店铺)均被过滤掉。不带“biz_”前缀
 * @param {boolean} options.filterOutAllBizIds // 是否过滤掉所有层级仅保留店铺，和filterOutAllShopNos不能同时为true，否则直接没有数据
 * @param {array} options.disabledBizIds // 禁用的层级id数组，只禁用当前层级，其下级依然有效。不带“biz_”前缀
 * @param {boolean} options.disabledAllBizIds // 禁用所有层级id，店铺可以选择
 * @param {boolean} options.disabledAllNonLeafNodeBizIds // 禁用所有拥有子层级（可以拥有店铺）的层级
 * @param {array} options.disabledShopNos // 禁用的店铺shopNo数组。不带“shop_”前缀，如：['189','9898','6666']
 * @param {boolean} options.hideAllShopNos // 隐藏所有的店铺，不在界面展示，但是会在选中的结果中返回
 * @param {array} options.filterValidProps 需要新增保留的属性。默认一定会保留“_value,_title,_rawTitle,_children,_hiddenShops,_parentId,disabled,_searchWords”
 * @param {string} options.treeNodeFilterProp 输入项过滤对应的 treeNode 属性
 * @return {promise} 返回Promise，转换后的层级数据 [{_value:'带“shop_”或“biz_”前缀，用于区分时店铺还是层级',_title,_rawTitle,_children:[和外层一样，可无限嵌套],_parentId,_searchWords,...}]
 */
export const transformRawData = (rawData, options = {}) => {
  return new Promise((resolve) => {
    if (!Array.isArray(rawData)) resolve([])

    const defaultOptions = {
      filterOutShopNos: [],
      filterOutAllShopNos: false,
      filterOutBizIds: [],
      filterOutAllBizIds: false,
      filterOutBizNoShop: false,
      disabledBizIds: [],
      disabledAllBizIds: false,
      disabledAllNonLeafNodeBizIds: false,
      disabledShopNos: [],
      hideAllShopNos: false,
      filterByUserId: undefined,
      filterValidProps: [],
      treeNodeFilterProp: '_searchWords',
    }
    const targetOptions = Object.assign({}, defaultOptions, options)
    const {
      filterOutAllShopNos,
      filterOutAllBizIds,
      hideAllShopNos,
      filterOutBizNoShop,
      filterByUserId,
    } = targetOptions

    // 标注一级节点
    _.each(rawData, (item) => (item._isTop = true))

    // 用于临时存放未被禁用和未被过滤的店铺
    let _tempShops = []

    /**
     * @description: 层级数组递归处理
     * @param {array} items 层级数组
     */
    const traverse = (items) => {
      for (const item of items) {
        const tempChildren = transformBiz(item, targetOptions)

        // 过滤掉所有店铺
        if (filterOutAllShopNos) {
          item._children = tempChildren
        } else {
          const tempShops = transformShops(item.shops, item.bizId, targetOptions)

          if (hideAllShopNos) {
            // 隐藏所有店铺
            item._children = tempChildren
            item._hiddenShops = tempShops
          } else {
            item._children = _.concat(tempChildren || [], tempShops)
          }

          _tempShops = [..._tempShops, ...tempShops]
        }

        filterProps(item, targetOptions)

        if (_.size(tempChildren)) {
          traverse(tempChildren)
        }
      }
    }
    traverse(rawData)

    // 过滤掉无效店铺和层级
    if ((filterOutBizNoShop && !filterOutAllShopNos) || filterByUserId) {
      forEach(
        rawData,
        (node) => {
          if (node._isBiz) {
            const { bizIds, shopNos } = getAllBizShop(node)
            node._hasBizIds = bizIds.length > 0
            node._hasShops = shopNos.length > 0
          }
        },
        treeConfig,
      )
      if (filterByUserId) {
        // 过滤掉没有有效店铺及层级的层级
        removeNode(
          rawData,
          (node) => !node._hasBizIds && !node._hasShops && node._isBiz,
          treeConfig,
        )
      } else {
        // 过滤掉没有有效店铺的层级
        removeNode(rawData, (node) => !node._hasShops && node._isBiz, treeConfig)
      }
    }

    resolve(filterOutAllBizIds ? _tempShops : rawData)
  })
}
