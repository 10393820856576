<template>
  <div class="flex items-start flex-wrap gap-4">
    <div class="flex flex-col items-center" v-for="user in renderUsers" :key="user.userId">
      <div class="relative size-[32px]">
        <div
          class="cursor-pointer absolute right-0 z-[999] flex translate-x-[50%] -translate-y-[50%] px-[4px] py-[2px] bg-red-500 rounded-full text-white"
          @click="remove(user.userId)"
        >
          <a-icon type="close" class="text-[12px]" />
        </div>
        <a-avatar class="align-baseline" shape="square" :src="user.avatar">
          <template v-if="!user.avatar">{{ user.userName }}</template>
        </a-avatar>
      </div>
      <span>{{ user.userName }}</span>
    </div>
    <a-avatar class="cursor-pointer" shape="square" icon="plus" @click="open" />

    <a-modal
      v-model="visible"
      title="人员选择"
      :body-style="{ height: '600px', overflow: 'auto' }"
      @ok="confirm"
    >
      <a-input-search v-model="searchKeyword" placeholder="输入姓名搜索" />
      <a-divider />
      <template v-if="!searchKeyword">
        <a-breadcrumb>
          <a-breadcrumb-item
            v-for="department in departmentBreadcrumb"
            :key="department.id"
            href=""
            @click.native="changeCurrentDepartment(department)"
          >
            {{ department.name }}
          </a-breadcrumb-item>
        </a-breadcrumb>

        <a-divider />

        <a-checkbox-group class="w-full" :value="checkedDepartmentIds">
          <div
            class="flex items-center"
            v-for="department in renderDepartmentTree"
            :key="department.id"
          >
            <a-checkbox
              class="flex-1"
              :value="department.id"
              @change="handleDepartmentChange(department, $event)"
            >
              {{ department.name }}
            </a-checkbox>

            <a-button
              class="flex-shrink-0"
              type="link"
              icon="cluster"
              @click="changeCurrentDepartment(department)"
            >
              下级
            </a-button>
          </div>
        </a-checkbox-group>
        <a-divider />
      </template>

      <a-checkbox-group :value="checkedUserIds">
        <div class="flex flex-col gap-y-2">
          <div v-for="user in renderUserList" :key="user.userId">
            <a-checkbox :value="user.userId" @change="handleUserCheckboxChange">
              {{ user.userName }}
            </a-checkbox>
          </div>
        </div>
      </a-checkbox-group>
    </a-modal>
  </div>
</template>

<script>
import { YcCrmDeptService, YcUserService } from '@ys/crm-api'
import { findNode, findPath } from '@ys/utils/lib/tree'
import { uniqBy } from 'lodash'

export default {
  props: {
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      visible: false,
      departmentTree: [],
      currentDepartmentId: undefined,
      checkedDepartmentIds: [],
      userList: [],
      checkedUserIds: [],
      searchKeyword: undefined,
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    departmentBreadcrumb() {
      return findPath(
        this.departmentTree,
        (department) => department.id === this.currentDepartmentId,
      )
    },
    renderDepartmentTree() {
      if (!this.currentDepartmentId) {
        return this.departmentTree
      }
      return (
        findNode(this.departmentTree, (department) => department.id === this.currentDepartmentId)
          ?.children ?? []
      )
    },
    renderUserList() {
      if (this.searchKeyword) {
        return uniqBy(
          this.userList.filter((user) => user.userName.includes(this.searchKeyword)),
          'userId',
        )
      }
      return this.userList.filter((user) => user.deptId === this.currentDepartmentId)
    },
    renderUsers() {
      return uniqBy(
        this.userList.filter((user) => this.modelValue.includes(user.userId)),
        'userId',
      )
    },
  },
  watch: {
    modelValue(value = []) {
      this.checkedUserIds = [...value]
      this.resetCheckedDepartmentIds()
    },
  },
  mounted() {
    this.getDepartmentTree()
    this.getUserList()
  },
  methods: {
    open() {
      this.searchKeyword = undefined
      this.visible = true
    },
    async getDepartmentTree() {
      const res = await YcCrmDeptService.getDepartmentTree()
      this.departmentTree = res.data.data
      this.currentDepartmentId = this.departmentTree?.[0]?.id
    },
    changeCurrentDepartment(department) {
      this.currentDepartmentId = department.id
    },

    async getUserList() {
      const res = await YcUserService.getUserAllDeptBlocked()
      this.userList = res.data.data ?? []
    },
    resetCheckedDepartmentIds() {
      this.checkedDepartmentIds = []
      this.departmentTree.forEach((department) => {
        this.resetDepartmentChecked(department)
      })
    },
    resetDepartmentChecked(department) {
      const departmentUser = this.userList.filter((user) => user.deptId === department.id)
      const isAllUserChecked = departmentUser.every((user) =>
        this.checkedUserIds.includes(user.userId),
      )
      let isAllChildChecked = true
      department.children.forEach((child) => {
        const isChildChecked = this.resetDepartmentChecked(child)
        if (!isChildChecked) {
          isAllChildChecked = false
        }
      })
      if (isAllUserChecked && isAllChildChecked && !this.isEmptyDepartment(department)) {
        this.checkedDepartmentIds.push(department.id)
      }
      return isAllUserChecked && isAllChildChecked
    },
    isEmptyDepartment(department) {
      const hasUser = this.userList.some((user) => user.deptId === department.id)
      if (hasUser) {
        return false
      }
      if (department.children?.length) {
        return department.children.every(this.isEmptyDepartment)
      }
      return true
    },
    handleDepartmentChange(department) {
      const foundCheckDepartmentIndex = this.checkedDepartmentIds.findIndex(
        (id) => id === department.id,
      )
      const check = foundCheckDepartmentIndex === -1
      this.setDepartmentCheck(department, check)
      department.children?.forEach((child) => this.setDepartmentCheck(child, check))
      this.resetCheckedDepartmentIds()
    },
    setDepartmentCheck(department, checked) {
      const departmentUserIds = this.userList
        .filter((user) => user.deptId === department.id)
        .map((user) => user.userId)
      if (checked) {
        departmentUserIds.forEach((userId) => {
          if (!this.checkedUserIds.includes(userId)) {
            this.checkedUserIds.push(userId)
          }
        })
        this.checkedDepartmentIds.push(department.id)
      } else {
        departmentUserIds.forEach((userId) => {
          const index = this.checkedUserIds.findIndex((id) => id === userId)
          if (index !== -1) {
            this.checkedUserIds.splice(index, 1)
          }
        })
        const foundCheckDepartmentIndex = this.checkedDepartmentIds.findIndex(
          (id) => id === department.id,
        )
        this.checkedDepartmentIds.splice(foundCheckDepartmentIndex, 1)
      }
    },
    handleUserCheckboxChange(e) {
      const { checked, value } = e.target
      if (!checked) {
        const index = this.checkedUserIds.findIndex((item) => item === value)
        this.checkedUserIds.splice(index, 1)
      } else {
        this.checkedUserIds.push(value)
      }
      this.resetCheckedDepartmentIds()
    },
    confirm() {
      this.modelValue = this.checkedUserIds
      this.visible = false
    },
    remove(userId) {
      const index = this.modelValue.indexOf(userId)
      this.modelValue.splice(index, 1)
    },
  },
}
</script>
