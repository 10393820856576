<template>
  <div style="cursor: pointer" @click.stop="handleImgClick">
    <img v-if="isImageFile" class="!w-[60px] !h-[60px] object-cover" :src="filePath" />
    <div
      v-else
      class="flex w-[60px] h-[60px] bg-[#3975c6] items-center justify-around bg-brand-primary text-white text-lg aspect-square"
      @click.stop="handleClick"
    >
      {{ fileExtension }}
    </div>
    <a-modal v-model="visible" @ok="visible = false" @cancel="visible = false">
      <img class="w-full h-full object-cover" :src="filePath" />
    </a-modal>
  </div>
</template>

<script>
const documentFileExtReg = /\.(doc|docx|xls|xlsx|ppt|pptx)$/i
const imageFileExtReg = /.(jpe?g|png|gif|bmp|svg|tiff?)$/i
export default {
  name: 'FilePreview',
  props: {
    filePath: { type: String, required: true },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    fileExtension() {
      return this.getFileExtension(this.filePath ?? '').toUpperCase()
    },
    isImageFile() {
      return imageFileExtReg.test(this.filePath)
    },
    href() {
      const filePath = this.filePath
      if (documentFileExtReg.test(filePath)) {
        return `https://view.officeapps.live.com/op/view.aspx?src=${this.filePath}`
      }
      return filePath
    },
  },
  methods: {
    getFileExtension(filename) {
      const lastDotIndex = filename.lastIndexOf('.')
      if (lastDotIndex === -1) {
        return ''
      }
      return filename.slice(lastDotIndex + 1)
    },
    handleImgClick() {
      this.visible = true
    },
    handleClick() {
      if (!this.filePath) return
      if (documentFileExtReg.test(this.filePath)) {
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${this.filePath}`, '_blank')
        return
      }
      window.open(this.filePath, '_blank')
    },
  },
}
</script>
