<template>
  <a-modal
    v-model="visible"
    :title="title"
    :confirm-loading="loading || uploading"
    @ok="submit"
    @cancel="close"
  >
    <a-form-model ref="form" :model="form">
      <a-form-model-item prop="content">
        <a-input v-model="form.content" placeholder="发表评论" />
      </a-form-model-item>
    </a-form-model>
    <a-form-model-item prop="fileList">
      <ys-upload
        v-model="form.fileList"
        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
        :max-count="3"
        :uploading.sync="uploading"
      />
      <span style="color: #8c8c8c"> 支持扩展名：.jpg .jpeg .png .pdf .doc .docx .xls .xlsx </span>
    </a-form-model-item>
  </a-modal>
</template>

<script>
import { YcAreaConfigService } from '@ys/crm-api'
import { getExtension } from '@ys/utils/lib/file'

import { FileType } from '@/constants/file'

export default {
  name: 'CommentAdd',
  props: {
    assId: {
      type: String,
      required: true,
    },
    assType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      uploading: false,
      form: {
        content: '',
        fileList: [],
      },
    }
  },
  computed: {
    title() {
      const { parentUserName } = this.form
      return parentUserName ? `回复${parentUserName}` : '评论'
    },
  },
  methods: {
    add(params) {
      this.form = {
        ...this.form,
        ...params,
      }
      this.visible = true
    },
    close() {
      this.form = {
        content: '',
        fileList: [],
      }
      this.visible = false
    },
    async submit() {
      this.loading = true
      try {
        await YcAreaConfigService.postCommentAdd({
          body: {
            ...this.form,
            fileList: this.form.fileList.map((file) => ({
              name: file.name,
              path: file.response,
              type: FileType.Comment,
              fileType: getExtension(file.name),
            })),
            assId: this.assId,
            assType: this.assType,
          },
        })
        this.$emit('refresh')
        this.$refs.form.resetFields()
        this.close()
      } finally {
        this.loading = false
      }
    },
    handleUploadChange(e) {
      this.form.fileList = e.fileList
        .filter((item) => item.response?.data)
        .map((file) => {
          const res = file.response?.data
          return {
            name: res.originalName,
            path: res.link,
            fileType: getExtension(file),
            type: FileType.Comment,
          }
        })
    },
  },
}
</script>
