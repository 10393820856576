<template>
  <a-radio-group v-model="modelValue" @change="handleChange">
    <div class="user-item" v-for="user in currentUserList" :key="user.userIdAndDepartmentId">
      <a-radio :value="user.userIdAndDepartmentId">
        <a-avatar
          style="background-color: #2483ff"
          size="small"
          :src="user.avatar"
          :alt="user.userName"
        >
          {{ user.userName }}
        </a-avatar>
        {{ user.userName }}
        <a-tag v-for="role in user.roleList" :key="`${user.userId}-${role.id}`">
          {{ role.name }}
        </a-tag>
      </a-radio>
    </div>
  </a-radio-group>
</template>

<script>
import { flatten } from '@ys/utils/lib/tree'
import { mapGetters } from 'vuex'

import { getUserAllDept } from '@/api/crm/user'

import { Translate } from './constants'

export default {
  props: {
    value: {
      type: String,
      default: undefined,
    },
    // 只显示当前登录用户
    onlySelfUser: {
      type: Boolean,
      default: false,
    },
    departmentTree: {
      type: Array,
      default: () => [],
    },
    departmentId: {
      type: String,
      default: undefined,
    },
    allUser: {
      type: Boolean,
      default: false,
    },
    keyWord: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userList: [],
    }
  },
  computed: {
    ...mapGetters(['roleList', 'userId']),
    modelValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    currentUserList() {
      if (this.keyWord) {
        return this.userList.filter((user) => user?.userName?.includes(this.keyWord))
      }
      return this.userList.filter((user) => user.deptId === this.departmentId)
    },
  },

  mounted() {
    this.getUserList()
  },
  methods: {
    async getUserList() {
      const userList = await getUserAllDept()
      const departmentList = flatten(this.departmentTree)
      this.userList = userList
        .filter((user) => {
          if (user.userId === this.userId) {
            return true
          }
          if (this.onlySelfUser) {
            return false
          }
          if (this.allUser) {
            return true
          }
          const department = departmentList.find((department) => department.id === user.deptId)

          return department && [Translate.Manager, Translate.Normal].includes(department.translate)
        })
        .map((user) => ({
          ...user,
          userIdAndDepartmentId: `${user.userId}-${user.deptId}`,
        }))
    },
    handleChange(e) {
      this.$emit('change', {
        value: e.target.value,
        option: this.userList.find((item) => item.userIdAndDepartmentId === e.target.value),
      })
    },
  },
}
</script>

<style scoped>
.user-item {
  padding: 8px 0;
}

.ant-radio-group {
  width: 100%;
  overflow: hidden;
}

.user-item .ant-radio-wrapper {
  width: 100%;
}
</style>
