<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item
        v-for="department in departmentBreadcrumb"
        :key="department.id"
        href=""
        @click.native="handleDepartmentClick(department)"
      >
        {{ department.name }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <template v-if="currentDepartmentList.length">
      <a-divider />
      <a-radio-group v-model="modelValue" @change="handleChange">
        <div
          class="department-item"
          v-for="department in currentDepartmentList"
          :key="department.id"
        >
          <a-radio
            v-if="showSelect && canDepartmentSelect(department)"
            class="department-item--name"
            :value="department.id"
          >
            {{ department.name }}
          </a-radio>
          <span v-else>{{ department.name }}</span>
          <a-button type="link" icon="cluster" @click="handleDepartmentClick(department)">
            下级
          </a-button>
        </div>
      </a-radio-group>
    </template>
  </div>
</template>

<script>
import { Translate } from '@ys/crm-common/dist/utils/departmentFilter'
import { findNode, findPath } from '@ys/utils/lib/tree'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: undefined,
    },
    departmentTree: {
      type: Array,
      default: () => [],
    },
    currentDepartment: {
      type: Object,
      default: undefined,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    permissionCheck: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Translate,
      breadcrumb: [],
    }
  },
  computed: {
    ...mapGetters(['roleList']),
    modelValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    currentDepartmentList() {
      if (!this.currentDepartmentModel) {
        return this.departmentTree ?? []
      }
      return this.currentDepartmentModel?.children ?? []
    },
    departmentBreadcrumb() {
      return findPath(this.departmentTree, (node) => node.id === this.currentDepartmentModel.id)
    },
    currentDepartmentModel: {
      get() {
        return this.currentDepartment
      },
      set(value) {
        this.$emit('update:current-department', value)
      },
    },
  },
  created() {
    const hasManagerRole = this.roleList.some((role) =>
      [Translate.Manager, Translate.Normal].includes(role.translate),
    )
    if (hasManagerRole) {
      findNode(this.departmentTree, (node) => {
        if ([Translate.Manager, Translate.Normal].includes(node.translate)) {
          this.currentDepartmentModel = node
          return true
        }
        return false
      })
    } else {
      this.currentDepartmentModel = this.findFirstLeafDepartment(this.departmentTree[0])
    }
  },
  methods: {
    findFirstLeafDepartment(node) {
      if (!node.children?.length) {
        return node
      }
      return this.findFirstLeafDepartment(node.children[0])
    },
    handleDepartmentClick(department) {
      this.currentDepartmentModel = department
    },
    handleChange(e) {
      this.$emit('change', {
        value: e.target.value,
        option: findNode(this.departmentTree, (node) => {
          return node.id === e.target.value
        }),
      })
    },
    canDepartmentSelect(department) {
      if (!this.permissionCheck) {
        return true
      }
      return department.translate === Translate.Manager
    },
  },
}
</script>

<style scoped>
.ant-radio-group {
  width: 100%;
}

.department-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.department-item .department-item--name {
  flex: 1;
}
</style>
