<template>
  <a-popover trigger="click">
    <a-button type="link">{{ messageReadUserStr }}</a-button>

    <template #content>
      <a-tabs class="w-[200px]">
        <a-tab-pane key="read" tab="已读">
          <div class="size-[200px] overflow-scroll flex flex-col gap-2">
            <div v-for="user in readList" :key="user.id">
              <a-avatar :size="30" :src="user.avatar">
                <template v-if="!user.avatar">{{ user.userName }}</template>
              </a-avatar>
              {{ user.userName }}
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="unread" tab="未读">
          <div class="size-[200px] overflow-scroll flex flex-col gap-2">
            <div v-for="user in unreadList" :key="user.id">
              <a-avatar :size="30" :src="user.avatar">
                <template v-if="!user.avatar">{{ user.userName }}</template>
              </a-avatar>
              {{ user.userName }}
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </template>
  </a-popover>
</template>

<script>
import { CrmYcMsgReadService } from '@ys/crm-api'

import { YesOrNoNum } from '@/constants/common'

export default {
  name: 'MessageReadUser',
  props: {
    msgId: { type: String, required: true },
    type: { type: Number, required: true },
  },
  data() {
    return {
      messageReadUserStr: '',
      readList: [],
      unreadList: [],
    }
  },
  mounted() {
    this.getReadStr()
    this.getReadList()
    this.getUnreadList()
  },
  methods: {
    async getReadStr() {
      const res = (
        await CrmYcMsgReadService.getMessageReadList({
          query: {
            pageNo: 1,
            pageSize: 3,
            msgId: this.msgId,
            type: this.type,
            status: YesOrNoNum.Yes,
          },
        })
      ).data.data
      if (!res.total) {
        this.messageReadUserStr = '0人已查看'
        return
      }
      const name = res.records.map((item) => item.userName).join('、')
      if (res.total < 3) {
        this.messageReadUserStr = `${name}已查看`
      } else {
        this.messageReadUserStr = `${name}...共${res.total}人已查看`
      }
    },
    async getReadList() {
      this.readList = (
        await CrmYcMsgReadService.getMessageReadList({
          query: {
            pageNo: 1,
            pageSize: 999,
            msgId: this.msgId,
            type: this.type,
            status: YesOrNoNum.Yes,
          },
        })
      ).data.data.records
    },
    async getUnreadList() {
      this.unreadList = (
        await CrmYcMsgReadService.getMessageReadList({
          query: {
            pageNo: 1,
            pageSize: 999,
            msgId: this.msgId,
            type: this.type,
            status: YesOrNoNum.No,
          },
        })
      ).data.data.records
    },
  },
}
</script>
