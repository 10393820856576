<template>
  <div class="flex">
    <!-- 日期选择器 -->
    <a-date-picker
      v-model="modelValue"
      :mode="currentDateType"
      :open="open"
      :value-format="format"
      :format="format"
      :allow-clear="allowClear"
      @panelChange="handlePanelChange"
      @openChange="handleStartOpenChange"
    />

    <div class="flex" style="align-items: center">
      <a-radio-group :value="currentDateType" @change="radioChange">
        <a-radio-button v-for="item in dateTypeList" :key="item.value" :value="item.value">
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export const CycleType = {
  Year: 'year',
  Month: 'month',
  Date: 'date',
}

const FormatType = {
  year: 'YYYY',
  month: 'YYYY-MM',
  date: 'YYYY-MM-DD',
}

const dateTypeList = [
  { value: CycleType.Year, name: '年' },
  { value: CycleType.Month, name: '月' },
  { value: CycleType.Date, name: '日' },
]
export default {
  props: {
    value: {
      type: String,
      default: undefined,
    },

    defaultRadioValue: {
      type: String,
    },
    dateTypeList: {
      type: Array,
      default: () => dateTypeList,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      currentDateType: CycleType.Date,
      CycleType,
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value) {
          value = moment(value).format(this.format)
        }

        this.$emit('input', value)

        this.$emit('change', { value, cycleType: this.currentDateType })
      },
    },
    format() {
      return FormatType[this.currentDateType]
    },
  },
  created() {
    if (this.defaultRadioValue) {
      this.currentDateType = this.defaultRadioValue
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
    },
    handleStartOpenChange(open) {
      this.open = open
    },
    async handlePanelChange(value) {
      this.modelValue = value

      this.open = false
    },
    radioChange(e) {
      this.currentDateType = e.target.value

      if (this.modelValue) {
        this.modelValue = moment(this.modelValue).format(this.format)
      }
    },
  },
}
</script>
<style lang="less" scoped></style>
