export const MessageType = {
  /** 操作记录未读 */
  Operation: 0,
  /** 消息提醒未读 */
  Message: 1,
  /** 新建跟进未读 */
  FollowUp: 2,
  /** 汇报 */
  Report: 3,
}
