import axios from 'axios'
import _ from 'lodash'
import router from '@/router'
import store from '@/store'
import { message as AntMessage } from 'ant-design-vue'
import crypto from '@ys/utils/lib/crypto'
import { clientId, clientSecret } from '@/config/defaultSettings'

const SUCCESS_CODE = '00000'

const instance = axios.create({
  // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // 自定义请求头 需要在nginx的Access-Control-Allow-Headers中进行配置才能允许进行跨域请求
  headers: {},
  // `withCredentials` 表示跨域请求时是否需要使用凭证
  withCredentials: true, // default
  // `timeout` 指定请求超时的毫秒数。 如果请求时间超过 `timeout` 的值，则请求会被中断
  timeout: 60000, // 默认值是 `0` (永不超时)
  // `responseType` 表示浏览器将要响应的数据类型
  // 选项包括: 'arraybuffer', 'document', 'json', 'text', 'stream'
  // 浏览器专属：'blob'
  responseType: 'json', // 默认值
})

/**
 * 异常拦截处理器
 * @param {Object} error 返回的错误信息
 * @returns
 */
const errorHandler = (error) => {
  // error: { config: {}, isAxiosError: true, request: {}, response: {}, message, stack }
  const { message, response } = error

  if (response) {
    // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
    // error.response: { config: {}, data: {}, headers: {}, request: {}, status: 404, statusText: "Not Found" }
    const { data, status } = response
    const loginPath = '/user/login'
    const isLoginPath = router.currentRoute.path === loginPath
    // code A0322-账号被冻结 A0230-用户登录已过期 A0301-访问未授权
    // status 401 Unauthorized 请求要求用户的身份认证
    const needLogin = status === 401 || ['A0322', 'A0230', 'A0301'].includes(data.code)
    if (needLogin && !isLoginPath) {
      // !isLoginPath 避免重复跳转到登录页
      store.dispatch('ALogout', true)
    }
    const errMsg = response.config.errMsg || data.msg || message
    // 如果不是显式设置config.errMsg为false，都进行toast提示
    if (response.config.errMsg !== false) {
      /**
       * @type {boolean|string} [errMsg]
       * axios.get(url, params, { errMsg: "错误提示语" })
       * 如果显示的传入 errMsg 为 false 则不显示默认提示, 在 catch 中自行处理
       */
      AntMessage.warn({
        key: `axiosMsg${errMsg}`,
        content: errMsg,
      })
    }
    // 为方便在异常处理中快捷使用业务服务器返回的错误信息，新增返回自定义参数errMsg
    // 可直接在 catch 中进行解构使用 .catch({ errMsg }) { console.log(errMsg) }
    error.errMsg = errMsg
  } else {
    // 发送请求时出了点问题
    AntMessage.warn({
      key: `axiosMsg${message}`,
      content: message,
    })
  }
  return Promise.reject(error)
}

/**
 * 请求拦截器 在发送请求之前做些什么
 * @param {Object} config 请求配置 https://www.axios-http.cn/docs/req_config
 * @param {Boolean} config.originalData [自定义参数] 是否在拦截器中返回服务端的原始数据
 * @param {Boolean|String} config.errMsg [自定义参数] 错误信息提示，默认为 undefined。当值为false时表示不通过AntMessage进行提示。如果有具体的值，当发生错误时使用该值进行提示
 * @param {*} config.* axios原生的参数配置
 */
instance.interceptors.request.use((config) => {
  const tenantId = store.getters.tenantInfo.tenantId
  const { erp, accessToken, deptId } = store.getters

  const headers = {
    // 基础认证，格式： Basic Base64(clientId:clientSecret)
    // 示例：Basic cGVyZm9ybWFuY2U6cGVyZm9ybWFuY2Vfc2VjcmV0
    Authorization: `Basic ${crypto.base64Encode(`${clientId}:${clientSecret}`)}`,
    nonce: crypto.uuidv4(), // （流水号）：随机值，可用UUID
    timestamp: new Date().getTime(), // 时间戳	当前系统时间戳，取13位长度的毫秒值

    // 权限相关头部
    deptId,
    ...config.headers,
  }
  // 接口签名
  headers.sign = crypto.httpSign(
    [tenantId, clientId],
    headers.nonce,
    headers.timestamp,
    config.params,
    config.data,
    clientSecret,
  )

  if (erp) headers.erp = erp
  if (tenantId) headers.tenantId = tenantId
  if (accessToken) headers.accessToken = accessToken

  config.headers = { ...config.headers, ...headers }
  return config

  // 对请求错误进行处理 errorHandler
}, errorHandler)

/**
 * 响应拦截器
 * @param {Object} response 响应数据 { config: {}, data: { code: "00000", data: {}, msg: "操作成功", success: true }, headers: {}, request: {}, status: 200, statusText: "OK" }
 */
instance.interceptors.response.use((response) => {
  // 2xx 范围内的状态码都会触发该函数
  // 在这里对响应数据进行处理

  // 如果 config.responseType 为 blob，则返回的 response.data 为 Blob类文件对象(https://developer.mozilla.org/zh-CN/docs/Web/API/Blob)
  // 如果 config.responseType 为 json，则返回的 response.data 为 Object。response.data: { code: '00000', data: {}, msg: '操作成功', success: true }
  const { status, data: resData, config, headers } = response
  if (['arraybuffer', 'blob'].includes(config.responseType)) {
    const fileName =
      headers['content-disposition'] &&
      _(headers['content-disposition'])
        .chain()
        .split('=')
        .last()
        .thru((val) => decodeURIComponent(val))
        .value()
    // 传入  _download = false 来自定义下载
    fileName && downloadFile(response, fileName)
    // fix: 兼容旧代码
    const blob = resData
    blob.fileName = fileName
    /** @deprecated res 属性后期移除 */
    blob.res = response
    blob.blob = blob
    return {
      data: {
        code: '00000',
        data: blob,
      },
    }
  }
  // 判断导出文件的格式
  if (status === 200) {
    if (resData.code && resData.code !== SUCCESS_CODE) {
      return errorHandler({ response })
    }
    // originalData 是否在拦截器中返回服务端的原始数据。可用于需要特殊处理的场景，如下载excel数据，通过headers获取文件名等。

    const result = response.config.originalData ? response : resData.data || resData
    return Promise.resolve(result)
  } else {
    // 如果不是 200，返回原始数据。在 catch 中进行异常处理
    return errorHandler({ response })
  }

  // 超出 2xx 范围的状态码都会触发 errorHandler 函数
}, errorHandler)

// 下载文件
function downloadFile(res, fileName) {
  const content = res.data
  // 获取文件名
  const blob = new Blob([content], {
    type: 'application/x-download',
  })
  if (navigator.userAgent.indexOf('Trident') > -1) {
    navigator.msSaveOrOpenBlob(blob, decodeURI(res.headers['content-disposition'].split('=')[1]))
  } else {
    const elink = document.createElement('a') // 创建a标签
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click() // 触发点击a标签事件
    document.body.removeChild(elink)
    URL.revokeObjectURL(elink.href)
  }
}
export default instance
