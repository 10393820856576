import { client } from '@ys/crm-api'
import crypto from '@ys/utils/lib/crypto'
import { message as AntMessage } from 'ant-design-vue'
import { AxiosError } from 'axios'

import { clientId, clientSecret } from '@/config/defaultSettings'
import router from '@/router'
import store from '@/store'

client.setConfig({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000,
  responseType: 'json',
  throwOnError: true,
})

const SUCCESS_CODE = '00000'

const needLogin = (status, code) => {
  return status === 401 || ['A0322', 'A0230', 'A0301'].includes(code)
}

client.instance.interceptors.request.use((request) => {
  const {
    accessToken,
    tenantInfo: { tenantId },
  } = store.getters
  request.headers
    .set('Authorization', `Basic ${crypto.base64Encode(`${clientId}:${clientSecret}`)}`)
    .set('tenantId', tenantId)
    .set('accessToken', accessToken)
  return request
})

client.instance.interceptors.response.use(
  (response) => {
    const { config, status, data } = response
    if (status === 200 && data.code === SUCCESS_CODE) {
      return response
    }
    const loginPath = '/user/login'
    const isLoginPath = router.currentRoute.path === loginPath
    if (needLogin(status, data.code) && !isLoginPath) {
      store.dispatch('ALogout', true)
      throw '重新登录'
    }
    const errorMessage = data.msg ?? '请求失败，请重试'
    AntMessage.warn({
      content: errorMessage,
    })
    const error = new AxiosError(errorMessage, data.code ?? status, config, config, response)
    return Promise.reject(error)
  },
  (error) => {
    const message = error?.response?.data?.msg ?? error.message ?? '请求失败，请重试'
    AntMessage.warn({
      content: message,
    })
    throw error
  },
)
