import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import getters from './getters'
import app from './modules/app'
import biz from './modules/biz'
import common from './modules/common'
import cron from './modules/cron'
import logs from './modules/logs'
import modalProcess from './modules/modalProcess'
import tags from './modules/tags'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    biz,
    user,
    common,
    logs,
    tags,
    cron,
    modalProcess,
  },
  state: {
    loginPath: '/user/login',
  },
  mutations: {},
  actions: {},
  getters,
  plugins: [
    createPersistedState({
      reducer({ user, app, common, logs }) {
        return { user, app, common, logs }
      },
    }),
  ],
})
