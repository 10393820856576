<template>
  <div class="m-m-t-8 comment">
    <a-button v-if="showAdd" type="link" icon="form" @click.stop="handleCommentAdd()">
      写评论
    </a-button>
    <div @click.stop>
      <comment-add ref="commentAdd" :ass-id="assId" :ass-type="assType" @refresh="getCommentList" />

      <div
        v-for="comment in commentList"
        :key="comment.id"
        class="comment-item"
        @click="handleCommentAdd(comment)"
      >
        <div class="content">
          <span class="name"> {{ getCommentTitle(comment) }}： </span>
          <span class="comment-text">{{ comment.content }}</span>
        </div>
        <div class="attach flex m-t-10px m-b-10px" v-if="comment.fileList.length > 0">
          <p>附件：</p>
          <div class="flex flex-1 flex-wrap">
            <file-preview
              v-for="item in comment.fileList"
              :key="item.path"
              :file-path="item.path"
              style="margin-right: 20px"
              @click.stop
            >
              {{ item.name }},
            </file-preview>
          </div>
        </div>
        <div class="footer">
          <span class="time">{{ comment.createTime }}</span>
          <span class="operation">
            <a-icon type="message" />
          </span>
        </div>
      </div>
      <a-pagination
        v-if="page.total && page.total !== 5"
        v-model="page.pageNo"
        class="m-m-t-8 m-m-b-8"
        :total="page.total"
        :page-size="pageSize"
        :show-total="(total) => `共${total}条评论`"
        @change="getCommentList"
      />
    </div>
  </div>
</template>

<script>
import { YcAreaConfigService } from '@ys/crm-api'

import FilePreview from '@/components/FilePreview.vue'

import CommentAdd from './CommentAdd.vue'

export default {
  name: 'Comment',
  components: {
    CommentAdd,
    FilePreview,
  },
  props: {
    assId: {
      type: String,
      required: true,
    },
    assType: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: [String, Number],
      default: 5,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      commentVisible: false,
      page: {
        pageNo: 1,
        total: 0,
      },
      commentList: [],
    }
  },
  mounted() {
    this.getCommentList()
  },
  methods: {
    async getCommentList() {
      const page = this.page
      const res = (
        await YcAreaConfigService.getCommentList({
          query: {
            assId: this.assId,
            pageSize: this.pageSize,
            pageNo: page.pageNo,
          },
        })
      ).data.data
      page.total = res.total
      this.commentList = res.records
    },
    getCommentTitle(comment) {
      if (!comment.parentUserName) {
        return comment.operateUserName
      }
      return `${comment.operateUserName} 回复 ${comment.parentUserName}`
    },
    handleCommentAdd({ id, operateUser, operateUserName } = {}) {
      if (!this.showAdd) {
        return
      }
      this.$refs.commentAdd.add({
        parentId: id,
        parentUser: operateUser,
        parentUserName: operateUserName,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.comment {
  background-color: #fafbfe;
}

.comment-item {
  cursor: pointer;
  background-color: #fff;
  padding: 20px;

  &:hover {
    background-color: #fafbfe;
  }
}

.comment-item .footer {
  display: flex;
  justify-content: space-between;
  .time {
    color: #a0a6b6;
  }
}
</style>
