import { filterDepartment } from '@ys/crm-common/dist/utils/departmentFilter'
import { findFirstLeafNode } from '@ys/utils/lib/tree'
import _ from 'lodash'

import { getDepartmentTree } from '@/api/crm/department'
import { getRoleInfo2 } from '@/api/crm/role'
import { getUserMenu } from '@/api/crm/user'
import { getUserInfo } from '@/api/crm/user'
import { getDomainInfo } from '@/api/oauth'
import { MENU_CODE } from '@/constants/menu'
import router from '@/router'
import { filterMenusByOwn, formatRoutes } from '@/router/formatRoutes'

const user = {
  state: {
    tenantInfo: {},
    userInfo: {}, // 当前登录用户个人信息(需要把用户登录成功后返回的数据合并在一起)
    roleInfo: {
      roleCodes: [],
      roleList: [],
      maxRoleCode: '',
      divisionDeptId: '',
      businessDeptId: '',
    },
    departmentTree: [],
    menus: [], // 系统原始菜单数据
    authCodes: [], // 权限码
    fromUrl: '', // 登录来源，如https://mdv-dev.wszn.top
  },
  getters: {
    authCodes: (state) => state.authCodes,
    roleList: (state) => state.roleInfo.roleList,
    departmentTree: (state) => state.departmentTree,
    firstMenuPath: (state) => {
      return (
        findFirstLeafNode(
          state.menus.find((item) => item.code === MENU_CODE.FRONTEND_TOP).children,
          (item) => item.type === 1,
        )?.path ?? '/'
      )
    },
    filteredDepartmentTree: (state, getters) =>
      filterDepartment(state.departmentTree, getters.roleList),
  },
  mutations: {
    // 更新用户个人信息
    SET_USER_INFO: (state, userInfo = {}) => {
      let _userInfo = userInfo
      // 需要进行合并：包括登录成功后获取的授权信息及通过接口获取的当前用户个人信息
      if (!_.isEmpty(userInfo)) {
        _userInfo = { ...state.userInfo, ...userInfo }
      }
      state.userInfo = _userInfo
    },
    // 更新原始菜单数据
    SET_MENUS: (state, menus = []) => {
      state.menus = menus
    },
    // 更新租户信息
    SET_TENANT_INFO(state, tenantInfo) {
      state.tenantInfo = tenantInfo
    },
    SET_AUTH_CODES(state, authCodes) {
      state.authCodes = authCodes
    },
    SET_ROLE_INFO(state, roleInfo) {
      state.roleInfo = roleInfo
    },
    SET_DEPARTMENT_TREE(state, departmentTree) {
      state.departmentTree = departmentTree
    },
  },
  actions: {
    // 统一对登录成功后的操作进行处理 oauthRes:登录接口返回的数据
    async LoginSuccess({ commit, dispatch, state, getters }, oauthRes) {
      commit('SET_USER_INFO', oauthRes)
      await Promise.all([
        dispatch('GetUserInfo', oauthRes.userId),
        dispatch('GetMenuList'),
        dispatch('getRoleInfo'),
      ])
      const menus = state.menus
      if (menus.length <= 0) {
        router.push({ path: '/user/lose' })
        return
      }
      const { redirectUri = getters.firstMenuPath } = router.currentRoute.query
      router.replace(decodeURIComponent(redirectUri))
    },
    /**
     * 获取用户个人信息
     * @param {*} userId 不传则获取当前登录的用户信息，并更新vuex；否则获取userId对应的用户信息
     * @returns userId对应的用户信息
     */
    async GetUserInfo({ commit, dispatch, getters }, userId) {
      try {
        const _userId = userId || getters.userId
        const userInfo = await getUserInfo({ userId: _userId })
        // 如果userId为当前登录用户id，则更新用户信息
        if (_userId === getters.userId) {
          commit('SET_USER_INFO', userInfo)
        }
        return userInfo
      } catch (error) {
        // 获取个人信息失败，需要清空本地缓存，否则会出现userInfo信息不全的问题
        dispatch('ALogout', true)
        throw error
      }
    },
    async getRoleInfo({ commit, dispatch }) {
      const { roleVoList, roleCodeList, maxRoleCode, division, business } = await getRoleInfo2()
      commit('SET_ROLE_INFO', {
        roleList: roleVoList,
        roleCodes: roleCodeList,
        divisionDeptId: division,
        businessDeptId: business,
        maxRoleCode,
      })
      dispatch('getDepartmentTree')
    },
    // 获取租户信息 在调用登录接口之前需要调用该接口获取租户信息用于登录
    async GetTenantInfo({ commit }, domain = window.location.hostname) {
      if (domain.indexOf('localhost') > -1 || domain.indexOf('192.168') > -1) {
        domain = new URL(process.env.VUE_APP_API_DOMAIN).hostname
      }
      const res = await getDomainInfo({ domain })
      commit('SET_TENANT_INFO', res)
      return res
    },
    // 获取当前登录用户的菜单权限
    async GetMenuList({ state, commit }) {
      const menus = await getUserMenu({ userId: state.userInfo.userId })
      const ownMenus = filterMenusByOwn(menus)
      const { authCodes, routes } = formatRoutes(ownMenus)
      router.addRoutes(routes)
      commit('SET_MENUS', ownMenus)
      commit('SET_AUTH_CODES', authCodes)
    },
    /**
     * @description: 清除用户缓存
     */
    async ClearUserStore({ commit }) {
      commit('SET_USER_INFO', {})
      commit('SET_MENUS', [])
      commit('DEL_ALL_TAG', {})

      localStorage.clear()
    },
    /**
     * 退出登录
     * @param {Boolean} jumpToLogin 是否需要跳转到登录页面
     */
    async ALogout({ commit }) {
      commit('SET_USER_INFO', {})
      commit('SET_MENUS', [])
      commit('DEL_ALL_TAG', {})
      // 清除 biz
      commit('CLEAR_DICT_LIST')

      location.href = router.resolve({ name: 'login' }).href
      return Promise.resolve()
    },
    async getDepartmentTree({ commit }) {
      const departmentTree = await getDepartmentTree()
      commit('SET_DEPARTMENT_TREE', departmentTree)
    },
  },
}
export default user
