export const FileType = {
  /** 线索 */
  Leads: 1,
  /** 商机 */
  Business: 2,
  /** 跟进记录 */
  FollowUp: 3,
  /** 合同 */
  Contract: 4,
  /** 问题 */
  Problem: 5,
  /** 货品 */
  Product: 6,
  /**方案 */
  Schema: 7,
  /** 看仓/拜访 */
  Visit: 8,
  /** 评论 */
  Comment: 9,
}
